
import { Vue, Component, Watch } from 'vue-property-decorator'
import auth from '@/store/modules/auth'
import app from '@/store/modules/app'
import { AuthPayload } from '@/models/dto'
import { RawLocation } from 'vue-router'
import AlertBox from '@/components/AlertBox.vue'
import { SplitFeatureFlag } from '@/utils/enum'
import { RedirectLoginOptions } from '@auth0/auth0-spa-js'

@Component({
  components: { AlertBox },
  metaInfo() {
    return {
      title: app.title,
      titleTemplate: `${this.$t('login.TITLE')} - %s`,
    }
  },
})
export default class Login extends Vue {
  isAuth0Enabled: boolean = false
  valid = false
  errorMessage = false
  isSubmitting = false
  passwordHidden = true
  email = ''
  password = ''
  emailRules = [
    (v) => !!v || this.$t('login.EMAIL_REQUIRED'),
    (v) => /.+@.+/.test(v) || this.$t('login.EMAIL_MUST_BE_VALID'),
  ]
  passwordRules = [(v) => !!v || this.$t('login.PASSWORD_IS_REQUIRED')]

  @Watch('isAuth0Enabled')
  onIsAuth0EnabledChanged() {
    this.goToAuth0Login()
  }

  async mounted(): Promise<void> {
    if (auth.isTokenSet) {
      this.redirect()
    }
    this.isAuth0Enabled = await this.checkIfAuth0IsEnabled()
  }

  async checkIfAuth0IsEnabled(): Promise<boolean> {
    // Fetch feature flag to determine if Auth0 is enabled
    // only use the queryparam if it is exactly 'true' or 'false'
    // ignore if it is something like ?auth0=t or ?auth0=f
    const auth0QueryParam = this.$route.query.auth0
    if (auth0QueryParam === 'true') {
      await this.$auth0.init()
      return true
    }
    if (auth0QueryParam === 'false') {
      return false
    }
    return this.$split.isFeatureEnabled(SplitFeatureFlag.Auth0SignIn)
  }

  async submit(): Promise<void> {
    if (this.isSubmitting) {
      return
    }
    const form = this.$refs.form as any
    const formIsValid = await form.validate()
    if (formIsValid) {
      this.isSubmitting = true
      const payload: AuthPayload = {
        email: this.email,
        password: this.password,
      }
      try {
        await auth.login(payload)
      } catch (error) {
        this.isSubmitting = false
        this.errorMessage = true
        return
      }
      form.reset()
      this.isSubmitting = false
      this.trackLogin()
      this.redirect()
    }
  }

  trackLogin(): void {
    this.$ga4Event('login', undefined)
  }

  redirect(): void {
    const to = (this.$route.query.redirectFrom || {
      name: 'quote-index',
    }) as RawLocation
    this.$router.push(to)
  }

  goToAuth0Login() {
    console.log('Executing goToAuth0Login')
    if (!this.isAuth0Enabled) {
      return
    }

    const options: RedirectLoginOptions = {
      appState: {
        routeName: 'home',
        redirectFrom: this.$route.query.redirectFrom,
      },
      authorizationParams: {
        redirect_uri: `${window.location.origin}/auth-callback`,
      },
    }

    this.$auth0.loginWithRedirect(options)
  }
}
